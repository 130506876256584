<template>
  <div>
    <div
      :style="getElementStyle"
      v-if="!loading && data.properties.filed_content != 'Hide'"
    >
      <!-- {{data.properties}} -->
      <!-- {{data.properties}} -->

      <el-row
        align="middle"
        v-if="
          !data.properties.hideLabel && data.properties.filed_content != 'Hide'
        "
      >
        <el-col
          :span="showLabel && !isDefalutPosq ? 4 : data.description ? 20 : 24"
        >
          <span>
            <label
              for="text"
              v-if="hasLabel"
              :style="getLabelStyles"
              :class="{ 'highlighted-label': highlight }"
            >
              {{ label || data.label }}
            </label>
          </span>
          <span
            v-if="data.validations.required && hasLabel"
            style="color: red; font-weight: bold"
          >
            *
          </span>
          <span style="margin-right: 5px">
            <el-popover
              v-if="data.description"
              placement="top-start"
              trigger="hover"
              :content="data.description"
            >
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </span>
        </el-col>
      </el-row>
      <el-row
        :span="showLabel && !isDefalutPos ? 24 : data.description ? 20 : 24"
      >
        <template v-if="data.inputType == 'STAR_RATING'">
          <el-rate
            v-if="!isView"
            :style="getStyle"
            v-model="form[data.key]"
            :allow-half="true"
            :max="5"
            :colors="['#99A9BF', '#F7BA2A', '#FF9900']"
            :suffix-icon="data.properties.el_icon"
            @change="applyFormRules"
            class="sle"
            :class="{ 'disabled-field': checkReadonly() }"
            :readonly="readonly()"
            :disabled="checkReadonly() || readonly()"
          >
          </el-rate>
        </template>
      </el-row>

      <el-row align="middle">
        <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
          <p v-if="hasError" style="color: red; font-size: 14px">
            {{ errorMsg }}
          </p>
          <p v-if="isView" :style="getStyle">
            <template v-if="form[data.key]">
              <!-- {{ form[data.key] }} -->
              <el-rate
                :style="getStyle"
                :value="form[data.key]"
                :allow-half="true"
                :max="5"
                :colors="['#99A9BF', '#F7BA2A', '#FF9900']"
                :suffix-icon="data.properties.el_icon"
                :disabled="isDisabled"
              >
              </el-rate>
            </template>
            <template v-else>-</template>
          </p>
        </el-col>
      </el-row>
    </div>
    <!-- <div v-else>
      <div v-if="isView">
        {{ form[data.key] }}
      </div>
      <div v-else>
        <el-input
          type="text"
          v-if="data.properties.filed_content != 'Hide'"
          :placeholder="data.placeholder || data.label"
          v-model="form[data.key]"
          :required="data.validations.required"
          :disabled="checkReadonly() || readonly()"
          :readonly="readonly()"
          :suffix-icon="data.properties.el_icon"
          :minlength="
              data.properties.minlength_value
                ? data.properties.minlength_value
                : 1
            "
          :maxlength="
              data.properties.maxlength_value
                ? data.properties.maxlength_value
                : 64
            "
          @change="applyFormRules"
        ></el-input>
      </div>
    </div> -->
  </div>
</template>
<script>
import userPermissionsHelper from '@/mixins/userPermissionsHelper';
import templateConstants from '../../../constants/lg-en/templates';
import { mapGetters } from 'vuex';
export default {
  name: 'templates-formComponentsExecute-StarRatingExecute',
  components: {},
  mixins: [userPermissionsHelper],
  props: [
    'data',
    'label',
    'form',
    'isView',
    'hasLabel',
    'isFromDocument',
    'fromRepeatable',
    'checkIsDisabled',
    'profilePage',
    'highlight',
  ],
  mounted() {
    this.loading = true;
    //if (this.data.default_value && !this.form[this.data.key]) {
    //  this.form[this.data.key] = this.data.default_value;
    //  this.$set(this.form, this.data.key, this.data.default_value);
    //}

    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == 'hide') {
        this.showLabel = false;
      }
      // if (this.data.styles.labelStyle == "right") {
      //   this.isDefalutPos = false;
      // }
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters('globalVariables', ['getGlobalVariableById']),
    getStyle() {
      return this.data.styles &&
        this.data.styles.label_color &&
        !this.fromRepeatable
        ? `height:${this.data.height - 30}px; font-weight: 400; width: ${
            this.data.width
          }px;`
        : 'height: 70px';
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}; `
        : 'font-weight: 700';
    },
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == 'right') {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      isList: false,
      hasError: false,
      errorMsg: '',
      showLabel: true,
      isDefalutPos: true,
      loading: false,
      inputValue: '',
      maskedValue: '',
      inputValue2: '',
      isDisabled: false,
    };
  },
  directives: {
    maskLastFour: {
      inserted(el) {
        el.addEventListener('blur', (event) => {
          const input = event.target;
          const inputValue = input.value;
          let maskedValue;
          if (inputValue.length <= 4) {
            maskedValue = inputValue;
          } else {
            let lastFourDigits = inputValue.slice(-4);
            maskedValue = '*'.repeat(inputValue.length - 4) + lastFourDigits;
          }
          input.value = maskedValue;

          input.dispatchEvent(new Event('blur'));
        });
      },
    },
  },

  methods: {
    applyFormRules() {
      this.$emit('applyFormRules');
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        'globalVariables/fetchGlobalVariableById',
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type === 'STAR_RATING') {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
        }
      }
    },
    readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === 'read_only' &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (this.data.is_global_variable == true) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === 'READ ONLY'
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === 'false') {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == 'hide') {
            this.showLabel = false;
          }
          // if (this.data.styles.labelStyle == "right") {
          //   this.isDefalutPos = false;
          // }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === 'DISABLED'
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
    // getElementStyles() {
    //   let borderStyle = "";
    //   if (this.data.styles) {
    //     let type = this.data.styles.border_type
    //       ? this.data.styles.border_type
    //       : "solid";
    //     let size = this.data.styles.border_size
    //       ? this.data.styles.border_size + "px"
    //       : "0px";
    //     let color = this.data.styles.border_color
    //       ? this.data.styles.border_color
    //       : "";
    //     let font_size =
    //       this.data.styles &&
    //       this.data.styles.font &&
    //       this.data.styles.font.font_size
    //         ? this.data.styles.font.font_size
    //         : "";
    //     let bold =
    //       this.data.styles &&
    //       this.data.styles.font &&
    //       (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
    //         ? "bold"
    //         : "";
    //     let italic =
    //       this.data.styles &&
    //       this.data.styles.font &&
    //       (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
    //         ? "italic"
    //         : "";

    //     borderStyle = "border:" + type + " " + " " + size + " " + color;

    //     borderStyle += ";";
    //     borderStyle += this.data.styles.background
    //       ? `background: ${this.data.styles.background}`
    //       : "";
    //     borderStyle += ";";
    //     borderStyle += "font-size:" + font_size + "px;";
    //     if (bold) {
    //       borderStyle += "font-weight:" + bold + ";";
    //     }
    //     if (italic) {
    //       borderStyle += "font-style:" + italic + ";";
    //     }
    //     borderStyle += this.data.styles.label_color
    //       ? `color: ${this.data.styles.label_color}`
    //       : "";
    //   }
    //   return borderStyle;
    // },
    validateEmail() {
      if (
        this.form[this.data.key] &&
        templateConstants.emailPattern.test(this.form[this.data.key])
      ) {
        this.errorMsg = '';
        this.hasError = false;
      } else {
        this.errorMsg = 'Invalid Email Format';
        this.form[this.data.key] = '';
        this.hasError = true;
      }
    },
    validatePassword() {
      if (
        this.form[this.data.key] &&
        templateConstants.passwordPattern.test(this.form[this.data.key])
      ) {
        this.errorMsg = '';
        this.hasError = false;
      } else {
        this.errorMsg = 'Invalid password Format';
        this.hasError = true;
      }
    },
    validateMasked() {
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.masked_type &&
        this.data.properties.masked_type === 'SSN'
      ) {
        this.inputValue2 = this.inputValue2.replace(' ', '').replace('-', '');
        if (
          this.inputValue2 &&
          templateConstants.ssnPattern.test(this.inputValue2)
        ) {
          this.errorMsg = '';
          this.hasError = false;
          this.form[this.data.key] = this.inputValue2;
          if (this.inputValue2.length <= 4) {
            this.maskedValue = this.inputValue2;
          } else {
            let lastFourDigits = this.inputValue2.slice(-4);
            this.maskedValue =
              '*'.repeat(this.inputValue2.length - 4) + lastFourDigits;
          }
          this.inputValue2 = this.maskedValue;
        } else {
          this.errorMsg = 'Invalid Masked Format';
          this.hasError = true;
        }
      } else if (
        this.data &&
        this.data.properties &&
        this.data.properties.masked_type &&
        this.data.properties.masked_type === 'CREDIT CARD'
      ) {
        this.inputValue2 = this.inputValue2.replace(' ', '').replace('-', '');
        if (
          this.inputValue2 &&
          templateConstants.creditCardPattern.test(this.inputValue2)
        ) {
          this.errorMsg = '';
          this.hasError = false;
          this.form[this.data.key] = this.inputValue2;
          if (this.inputValue2.length <= 4) {
            this.maskedValue = this.inputValue2;
          } else {
            let lastFourDigits = this.inputValue2.slice(-4);
            this.maskedValue =
              '*'.repeat(this.inputValue2.length - 4) + lastFourDigits;
          }
          this.inputValue2 = this.maskedValue;
        } else {
          this.errorMsg = 'Invalid Masked Format';
          this.hasError = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.highlighted-label {
  color: red;
}
.sle {
  width: 93% !important;
  display: flex;
}
</style>
